import { template } from '@shein/common-function'

/**
 * QuickShipTag 组件 props
 * @param {Object} data - 商品行数据
 * @param {Object} config - 当前组件 props
 * @param {Object} constant - 常量配置
 * @param {Object} language - 多语言
 */
export function getProps({ data, language, constant }) {
  const { cartQuickshipVisual } = data.value
  const { SHEIN_KEY_PC_30515, SHEIN_KEY_PC_23485 } = language.value
  const isShowNewStyle = cartQuickshipVisual && (cartQuickshipVisual === 'quickship' || isNumberOrString(cartQuickshipVisual))

  return {
    isShow: data.value.isShowQuickShipTag && !constant.value.IS_PLATFORM,
    isShowMask: data.value.isSoldOutItem || data.value.is_checked !== 1 && data.value.isGiftItem,
    text: isNumberOrString(cartQuickshipVisual) ? template(cartQuickshipVisual, SHEIN_KEY_PC_30515) : SHEIN_KEY_PC_23485,
    isShowTimeText: isShowNewStyle && data.value.isShowTimeText,
    maxWidth: '100%',
    isShowNewStyle,
    timeText: '',
  }
}

export function getEvents() {
  return {}
}

/**
 * 判断是否是数字或者数字字符串
 * @param {*} value
 * @returns
 */
const isNumberOrString = (value) => (typeof value === 'number' || typeof value === 'string') && !isNaN(Number(value))
