/**
 * GoodsStore 组件 props
 * @param {Object} data - 商品行数据
 * @param {Object} config - 当前组件配置
 * @param {Object} constant - 常量配置
 * @param {Object} language - 多语言
 */
export function getProps({ data }) {
  let type = 'normal'
  if (data.value.preferred_seller_store === 1) {
    type = 'preferred-seller'
  }

  return {
    isShow: false,
    isShowMask: data.value.isSoldOutItem || data.value.is_checked !== 1 && data.value.isGiftItem,
    isClick: false,
    type,
    text: data.value.store_title ?? '',
    isNewStoreIcon: data.value.isNewStoreIcon,
    storeIconFontSize: '16px',
    textFontSize: '13px',
    textFontWeight: 'normal',
    maxWidth: '100%',
  }
}

export function getEvents() {
  return {}
}
